<template>
  <div :id="id" :class="['m-park-settled-service', 'card-spacing', isPC ? 'z-web' : 'z-phone', theme.primaryColor ? theme.primaryColor : '']">
    <VisualTitle :title="title" @visual="handleVisual" />
    <VisualItem v-if="isPC" :visualKeys="list.visualKeys" @visual="handleVisual" class-name="m-list f-clearfix">
      <div
        v-for="(item, index) in list.value"
        :key="index"
        class="m-card g-l"
      >
        <VisualItem :visualKeys="[item.image.visualKeys, item.image_2x.visualKeys]" @visual="handleVisual">
          <CMSImageBg
            v-if="(item.image && item.image.value) && (item.image_2x && item.image_2x.value)"
            :img="item.image ? item.image.value : ''"
            :img2x="item.image_2x ? item.image_2x.value : ''"
            class-name="card-img"
          />
        </VisualItem>
        <p class="card-title">
          <VisualItem :visualKeys="item.title.visualKeys" @visual="handleVisual">
            {{ item.title.value }}
          </VisualItem>
        </p>
        <p class="card-desc">
          <VisualItem :visualKeys="item.desc.visualKeys" @visual="handleVisual" class-name="card-desc">
            {{ item.desc.value }}
          </VisualItem>
        </p>
      </div>
    </VisualItem>
    <VisualItem v-else :visualKeys="list.visualKeys" @visual="handleVisual">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="(item, index) in list.value" :key="`swiper-${index}`" class="swiper-slide">
            <div style="width: 100%; height: 100%; position: relative;transform:translate3d(0,0,0);">
              <VisualItem :visualKeys="[item.image_2x_h5.visualKeys, item.image_3x_h5.visualKeys]" @visual="handleVisual">
                <CMSImageBg
                  :img="item.image_2x_h5.value ? item.image_2x_h5.value : item.image.value"
                  :img2x="item.image_3x_h5.value ? item.image_3x_h5.value : item.image_2x.value"
                  class-name="card-img"
                />
              </VisualItem>
              <VisualItem :visualKeys="item.title.visualKeys" @visual="handleVisual">
                <p class="card-title">
                  {{ item.title.value }}
                </p>
              </VisualItem>
              <VisualItem :visualKeys="item.desc.visualKeys" @visual="handleVisual" :class="['card-desc-item' ,index === active ? 'card-desc-bg-big' : 'card-desc-bg']">
                <p v-if="index===active" class="card-desc">
                  {{ item.desc.value }}
                </p>
                <p v-else class="card-desc">
                  {{ item.desc.value| ellipsis }}
                </p>
                <p v-show="active !== index" @click="showHandel(item, index)" class="card-open-desc">
                  展开&nbsp;<img src="/ParkSettledService/open.svg">
                </p>
                <p v-show="active === index" @click="closeHandel(index)" class="card-open-desc">
                  收起&nbsp;<img src="/ParkSettledService/close.svg">
                </p>
              </VisualItem>
            </div>
          </div>
        </div>
      </div>
    </VisualItem>
    <div v-if="moreLinkText && moreLinkText.value" class="u-more">
      <VisualItem :visualKeys="[moreLinkText.visualKeys, moreLink.visualKeys]" @visual="handleVisual">
        <CMSLink
          :link="moreLink.value"
          class-name="more-btn"
        >
          {{ moreLinkText.value }}
        </CMSLink>
      </VisualItem>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { Swiper as SwiperClass, Navigation, Pagination } from 'swiper'
import config from './config.json'
import Load from '~/assets/js/load.js'
import { fixConfig } from '~/assets/js/visualFix'
import VisualItem from '~/components/common/VisualItem'
import CMSLink from '~/components/common/Link'
import CMSImageBg from '~/components/common/ImageBg'
import 'swiper/swiper-bundle.css'
import VisualTitle from '~/components/common/VisualTitle'

const componentKey = 'ParkSettledService'

export default {
  filters: {
    ellipsis(_val) {
      if (!_val) return ''
      if (_val.length > 28) {
        return _val.slice(0, 28) + '...'
      }
      return _val
    }
  },
  components: {
    VisualItem,
    CMSImageBg,
    CMSLink,
    VisualTitle
  },
  props: {
    id: {
      type: [String, Number],
      default: componentKey
    },
    mode: {
      type: String,
      default: ''
    },
    reqData: {
      type: Object,
      default() {
        return {}
      }
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const data = fixConfig(_.cloneDeep(config[componentKey]), this.mode === 'dev' ? {
      id: componentKey,
      reqData: Load(_.cloneDeep(config), componentKey)
    } : this.$store.getters.getComponent(this.id))

    this.$store.commit('updateComponentById', {
      id: this.id || componentKey,
      data
    })

    const temp = {
      ...data,
      freeMode: true,
      swiper: undefined,
      showDesc: false,
      active: ''
    }

    // console.log(componentKey)
    // console.log(temp)

    return temp
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  mounted() {
    SwiperClass.use([Navigation, Pagination])
    this.swiper = new SwiperClass('.swiper-container', {
      slidesPerView: 'auto',
      autoplay: true,
      freeMode: {
        momentumBounce: false
      },
      observer: true,
      observeParents: true
    })
  },
  methods: {
    handleVisual(obj) {
      this.$emit('visual', obj)
    },
    showHandel(item, index) {
      this.active = index
      this.showDesc = true
    },
    closeHandel(index) {
      this.active = ''
      this.showDesc = false
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';

.m-park-settled-service {
  &.z-phone {
    margin: 0 auto;
    padding: pxtorem(80) 0 0 0;
    background: #f7f8fa;

    .swiper-container {
      width: 100%;
      height: pxtorem(698);
      /* padding-top: pxtorem(30); */
      margin-top: pxtorem(50);

      .swiper-wrapper {
        .swiper-slide {
          width: pxtorem(544);
          height: pxtorem(628);
          margin-right: pxtorem(40);
          position: relative;
          overflow: hidden;
          background: #fff;
          box-shadow: 0 pxtorem(8) pxtorem(40) rgba(47, 56, 111, 0.156305);
          /* padding-left: pxtorem(2);
          margin-left: pxtorem(-2); */
          transform:translate3d(0,0,0);
          overflow:hidden;

          &:first-child {
            margin-left: pxtorem(40);
          }

          .card-img {
            width: pxtorem(544);
            height: pxtorem(508);
            background-size: cover;
          }

          .card-title {
            line-height: pxtorem(50);
            padding: pxtorem(36) pxtorem(40);
            font-size: pxtorem(36);
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .card-desc-item {
            transition: all 0.3s;
          }

          .card-desc-bg {
            width: pxtorem(544);
            height: pxtorem(342);
            position: absolute;
            bottom: pxtorem(120);
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);

            .card-desc {
              font-size: pxtorem(26);
              color: #fff;
              position: absolute;
              bottom: pxtorem(40);
              padding: 0 pxtorem(40);
              line-height: pxtorem(52);
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .card-open-desc {
              position: absolute;
              bottom: pxtorem(48);
              right: pxtorem(46);
              color: #fff;
              font-weight: 600;
            }
          }

          .card-desc-bg-big {
            width: pxtorem(544);
            height: pxtorem(506);
            position: absolute;
            bottom: pxtorem(120);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));

            .card-desc {
              font-size: pxtorem(26);
              padding: pxtorem(112) pxtorem(28) 0;
              line-height: pxtorem(52);
              color: #fff;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
            }

            .card-open-desc {
              position: absolute;
              bottom: pxtorem(50);
              right: pxtorem(46);
              color: #fff;
              font-weight: 600;
            }
          }
        }
      }
    }

    .u-more {
      display: block;
      text-align: center;
      margin-bottom: pxtorem(20);

      .more-btn {
        display: block;
        width: pxtorem(264);
        height: pxtorem(80);
        font-size: pxtorem(28);
        color: #fff;
        text-align: center;
        position: relative;
        background-color: $baseBlue;
        margin: 0 auto;
        line-height: pxtorem(80);
      }
    }

    &.red {
      .u-more .more-btn {
        background-color: $baseRed;
      }
    }
  }

  &.z-web {
    max-width: 1200px;
    margin: 0 auto;
    /* padding: 56px 0; */
    /* padding-bottom: 60px; */

    .m-list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 50px;
    }

    .m-card {
      width: 382px;
      margin-left: 27px;

      &:first-child {
        margin-left: 0;
      }

      &:nth-child(4) {
        margin-left: 0;
      }

      &:nth-child(n+4) {
        clear: both;
        margin-top: 50px;
      }

      .card-img {
        width: 382px;
        height: 280px;
        background-size: cover;
      }

      .card-title {
        color: #333;
        font-size: 20px;
        font-weight: bold;
        padding: 24px 0 0;
        position: relative;
        line-height: 28px;
        height: 55px;

        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .card-desc {
        color: #666;
        line-height: 24px;
        padding-top: 8px;
        margin-bottom: 8px;
      }
    }

    .u-more {
      display: block;
      text-align: center;

      .more-btn {
        display: block;
        width: 132px;
        height: 40px;
        font-size: 14px;
        color: #fff;
        text-align: center;
        position: relative;
        background-color: $baseBlue;
        margin: 40px auto 0;
        padding-top: 7px;
        line-height: 28px;
      }
    }

    &.red {
      .m-card .card-title::after {
        background-color: $baseRed;
      }

      .more-btn {
        background-color: $baseRed;
      }
    }
  }
}
</style>
